import React from "react"
import Masthead from '../components/Masthead/styles.css'
import Layout from "../components/Layout"
import SEO from "../components/seo"
import BlockSimple from '../components/BlockSimple'
import "../components/ImagePuzzleA/styles.css"


const AboutPage = () => (
  <Layout>
    <div className="layout__margin-bottom">
      <SEO title="Costa Rica Best Choice" />
      <div className="masthead__wrapper">
        <div className="masthead-destinations"></div>
      </div>
      <div className="block-simple__bg">
        <div className="block-simple__wrapper">
          <h1 className="block-simple__heading">Destinations</h1>
          <div className="block-simple__text">We organize transportation for guests visiting anywhere within the Central and Northern region of Costa Rica. We can easily pick you up in either of the airports to safely deliver you to your destination. Just a ride or a full day tour, we offer the best prices and highest standards.</div>
          <div className="block-simple__text">Throughout the years we have found some of the most beautiful hidden treasures of the country. We have reliable places where we take our guests to enjoy great quality meals and experience the rawness the country has to offer. Request a tour of a specific region or simply mention activites you would like to do and we can plan a custom experience for you and your group.</div>
        </div>
      </div>
      <div className="imagePuzzleA__wrapper">
        <div className="imagePuzzleA">
          <div className="imagePuzzleA__1 imagePuzzleA__img"></div>
          <div className="imagePuzzleA__2 imagePuzzleA__img"></div>
          <div className="imagePuzzleA__3 imagePuzzleA__img"></div>
        </div>
      </div>
    </div>


  </Layout>
)

export default AboutPage
